.card {
    position: relative;
    aspect-ratio: 2 / 3;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    max-width: 300px;
    margin: 0 auto;
}

.card:hover {
    transform: translateY(-5px) scale(1.02);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
}

.card.selected {
    border: 3px solid var(--primary-color, #3f51b5);
    box-shadow: 0 0 20px rgba(63, 81, 181, 0.8);
}

.cardMedia {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: all 0.5s ease;
}

.card:hover .cardMedia {
    transform: scale(1.1) rotate(2deg);
}

.cardContent {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.5rem;
    background: linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.6) 50%, rgba(0,0,0,0) 100%);
    color: white;
    transition: all 0.3s ease;
}

.cardTitle {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.75rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.cardDescription {
    font-size: 0.95rem;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s ease;
    line-height: 1.4;
}

.card:hover .cardDescription {
    opacity: 1;
    max-height: 150px;
    margin-top: 0.5rem;
}

.cardBadge {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: var(--primary-color, #3f51b5);
    color: #ffffff;
    padding: 0.35rem 0.75rem;
    border-radius: 25px;
    font-size: 0.85rem;
    font-weight: 600;
    opacity: 0;
    transform: translateY(-15px);
    transition: all 0.4s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.card:hover .cardBadge {
    opacity: 1;
    transform: translateY(0) scale(1.05);
}

.cardIcon {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 45px;
    height: 45px;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card:hover .cardIcon {
    background-color: var(--primary-color, #3f51b5);
    color: #ffffff;
    transform: rotate(360deg) scale(1.1);
}

@media (max-width: 768px) {
    .cardGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
    }
    
    .card {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .cardGrid {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    
    .card {
        aspect-ratio: 16 / 9;
    }
}
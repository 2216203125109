@import '../variables.module.css';
@import '../styles/global.module.css';

/* Setup Box Styles */
.setupBox {
  background-color: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 20px;
  padding: 4.5rem 3.5rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.05);
  text-align: center;
  animation: fadeIn 0.5s ease-out, floatAnimation 6s ease-in-out infinite;
  position: relative;
  max-width: 800px;
  width: 90%;
  margin: 2rem auto;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.setupBox:hover {
  transform: translateY(-8px) rotate(0.25deg);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.15), 0 8px 18px rgba(0, 0, 0, 0.08);
}

.setupBox::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(110,142,251,0.1) 0%, rgba(167,119,227,0.05) 50%, rgba(255,255,255,0) 70%);
  transform: rotate(30deg);
  pointer-events: none;
  animation: shimmer 12s linear infinite;
}

/* Title Styles */
.setupTitle {
  color: #6e8efb;
  font-size: 3.8rem;
  font-weight: 800;
  margin-bottom: 1.8rem;
  letter-spacing: -1px;
  line-height: 1.2;
  background: linear-gradient(45deg, #6e8efb, #a777e3);
  -webkit-background-clip: text;
  background-clip: text;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  -webkit-text-fill-color: transparent;
}

.setupSubtitle {
  color: #5a5a5a;
  font-size: 1.7rem;
  margin-bottom: 3rem;
  font-weight: 400;
  line-height: 1.4;
}

/* Form Styles */
.formControl {
  position: relative;
  margin-bottom: 2rem !important;
}

.input {
  width: 100%;
  padding: 1.2rem 1.2rem 0.6rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1.1rem;
  background-color: #f8f8f8;
  transition: all 0.4s ease;
}

.input:focus {
  border-color: #6e8efb;
  background-color: #ffffff;
  box-shadow: 0 0 0 4px rgba(110, 142, 251, 0.15);
}

.inputLabel {
  position: absolute;
  left: 1rem;
  top: 1rem;
  color: #666;
  transition: all 0.3s ease;
  pointer-events: none;
  font-size: 1.1rem;
}

.input:focus + .inputLabel,
.input:not(:placeholder-shown) + .inputLabel {
  transform: translateY(-1rem) scale(0.75);
  color: #6e8efb;
}

.icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

.select {
  appearance: none;
  padding-right: 2.5rem; /* Space for the custom dropdown icon */
}

.selectIcon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Button Styles */
.setupButton {
  margin-top: 3rem !important;
  background: linear-gradient(45deg, #6e8efb, #a777e3, #6e8efb) !important;
  background-size: 200% 200% !important;
  color: white !important;
  font-size: 1.4rem !important;
  font-weight: bold !important;
  padding: 1.5rem 3.5rem !important;
  border-radius: 50px !important;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
  animation: gradientShift 5s ease infinite !important;
}

.setupButton:hover {
  transform: translateY(-6px) scale(1.06);
  box-shadow: 0 18px 36px rgba(0, 0, 0, 0.18);
}

.setupButton:active {
  transform: translateY(-3px) scale(0.99);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-50%) rotate(30deg);
  }
  100% {
    transform: translateX(50%) rotate(30deg);
  }
}

@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .setupBox {
    padding: 3.5rem 2.5rem;
    max-width: 95%;
  }

  .setupTitle {
    font-size: 3rem;
  }

  .setupSubtitle {
    font-size: 1.5rem;
  }

  .setupButton {
    padding: 1.3rem 2.6rem !important;
    font-size: 1.3rem !important;
  }
}

/* Accessibility Improvements */
@media (prefers-reduced-motion: reduce) {
  .setupBox,
  .setupButton,
  .select,
  .input {
    transition: none;
    animation: none;
  }
}

/* New: Focus styles for better keyboard navigation */
.input:focus,
.select:focus,
.setupButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(110, 142, 251, 0.5);
}

/* New: Improved contrast for better readability */
.setupSubtitle {
  color: #5a5a5a;
}

.inputLabel {
  color: #666;
}

/* New: Smooth scrolling for the whole page */
html {
  scroll-behavior: smooth;
}

/* ... rest of the existing styles ... */


.scriptContainer {
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .scriptText {
    white-space: pre-wrap;
    font-family: 'Courier Prime', monospace;
    line-height: 1.6;
    font-size: 12pt;
    color: var(--text-color, #333333);
  }
  
  .sceneHeading {
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 2em;
    margin-bottom: 1em;
    color: var(--primary-color, #3f51b5);
  }
  
  .characterName {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.5em;
    margin-left: 15em;
    color: var(--secondary-color, #f50057);
  }
  
  .dialogue {
    margin-left: 10em;
    margin-right: 10em;
    margin-bottom: 1em;
  }
  
  .parenthetical {
    font-style: italic;
    color: #666;
    margin-left: 12em;
    margin-right: 12em;
    margin-bottom: 0.5em;
  }
  
  .action {
    margin-bottom: 1.5em;
  }
  
  .transition {
    font-style: italic;
    text-align: right;
    margin: 2em 0 1em 0;
    color: var(--transition-color, #ff5722);
    font-weight: 700;
  }
  
  .pageBreak {
    page-break-after: always;
    border-bottom: 1px dashed #ccc;
    margin: 2em 0;
  }
  
  .actBreak {
    text-align: center;
    font-weight: 700;
    margin: 3em 0;
    text-transform: uppercase;
  }
  
  .sceneNumber {
    float: right;
    color: #888;
    font-size: 0.9em;
  }
  
  .dualDialogue {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
  }
  
  .dualDialogue .characterName,
  .dualDialogue .dialogue {
    width: 45%;
    margin-left: 0;
    margin-right: 0;
  }
  
  .continuedTop {
    text-align: right;
    font-size: 0.9em;
    color: #888;
    margin-bottom: 0.5em;
  }
  
  .continuedBottom {
    text-align: left;
    font-size: 0.9em;
    color: #888;
    margin-top: 0.5em;
  }
  
  .moreDialogue {
    text-align: right;
    font-size: 0.9em;
    color: #888;
    margin-top: -0.5em;
    margin-bottom: 0.5em;
  }
  
  @media print {
    .scriptContainer {
      box-shadow: none;
      max-height: none;
      overflow-y: visible;
    }
  
    .pageBreak {
      page-break-after: always;
    }
  }
  
  @media (max-width: 768px) {
    .scriptContainer {
      padding: 1rem;
      max-height: 70vh;
    }
  
    .scriptText {
      font-size: 10pt;
    }
  
    .characterName {
      margin-left: 8em;
    }
  
    .dialogue {
      margin-left: 5em;
      margin-right: 5em;
    }
  
    .parenthetical {
      margin-left: 6em;
      margin-right: 6em;
    }
  }
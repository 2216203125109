@import '../variables.module.css';
@import '../styles/card.module.css';

/* Specific styles for Group Play */
.playerPrompt {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.playerPrompt:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.categorySections {
  margin-bottom: 3rem;
}

.categoryTitle {
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  position: relative;
  padding-bottom: 0.5rem;
}

.categoryTitle::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: var(--secondary-color);
  border-radius: 2px;
}

.cardsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
}

/* Assigned Characters Section */
.assignedCharactersSection {
  margin-top: 3rem;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.assignmentCard {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
}

.assignmentCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .playerPrompt {
    font-size: 1.4rem;
    padding: 1rem;
  }

  .categoryTitle {
    font-size: 1.4rem;
  }

  .cardsGrid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .cardsGrid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

@media (min-width: 1025px) {
  .cardsGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}
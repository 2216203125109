/* variables.module.css */
:root {
    /* Colors */
    --primary-color: #6a0dad;
    --secondary-color: #ffffff;
    --accent-color: #ffd700;
    --text-color: #333333;
    --background-color: #f5f5f5;
    --error-color: #ff4136;
    --success-color: #2ecc40;

    /* Typography */
    --font-family-primary: 'Arial', sans-serif;
    --font-family-secondary: 'Georgia', serif;
    --font-size-base: 16px;
    --line-height-base: 1.5;

    /* Spacing */
    --spacing-small: 0.5rem;
    --spacing-medium: 1rem;
    --spacing-large: 2rem;

    /* Layout */
    --border-radius: 4px;
    --max-width-container: 1200px;

    /* Transitions */
    --transition-speed: 0.3s;
}

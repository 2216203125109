/* /Users/jackson/skitz/src/pages/AuthPage.module.css */

@import '../variables.module.css';
@import '../styles/global.module.css';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  padding: 2rem;
}

.authCard {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 3rem 2rem;
  width: 100%;
  max-width: 420px;
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.authCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
}

.title {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  color: #4a4af4;
  margin-bottom: 0.5rem !important;
  font-weight: 700;
  font-size: 2.5rem;
  letter-spacing: 0.5px;
}

.titleWord {
  display: inline-block;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
}

.titleWord::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #ff4081;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.titleWord:hover::after {
  transform: scaleX(1);
}

.titleWord:hover {
  transform: scale(1.1);
  color: #ff4081;
}

.subtitle {
  color: #6c757d;
  margin-bottom: 1.5rem !important;
  font-size: 1.2rem;
  font-weight: 500;
}

.description {
  color: #6c757d;
  margin-bottom: 2rem !important;
}

.googleButton {
  background-color: #4285f4 !important;
  color: white !important;
  margin-bottom: 1.5rem !important;
  text-transform: none !important;
  padding: 0.75rem !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.googleButton:hover {
  background-color: #357ae8 !important;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.buttonProgress {
  color: white !important;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.divider span {
  padding: 0 1rem;
  color: #6c757d;
  font-weight: 500;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.input {
  background-color: #f8f9fa;
  border-radius: 8px;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.input:focus {
  background-color: #ffffff;
  border-color: #4a4af4;
  box-shadow: 0 0 0 2px rgba(74, 74, 244, 0.2);
}

.emailButton {
  background-color: #4a4af4 !important;
  color: white !important;
  text-transform: none !important;
  padding: 0.75rem !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.emailButton:hover {
  background-color: #3b3bb3 !important;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.toggleText {
  color: #4a4af4;
  cursor: pointer;
  margin-bottom: 1rem !important;
  font-weight: 500;
  transition: all 0.3s ease;
}

.toggleText:hover {
  text-decoration: underline;
  color: #3b3bb3;
}

.privacyNote {
  color: #6c757d;
  font-size: 0.85rem;
  line-height: 1.4;
  max-width: 80%;
  margin: 0 auto;
}

.active {
  color: #3f51b5;
  transform: translateY(-5px);
}

@media (max-width: 600px) {
  .container {
    padding: 1rem;
  }

  .authCard {
    padding: 2rem 1.5rem;
  }

  .title {
    font-size: 1.75rem;
  }

  .divider span {
    padding: 0 0.75rem;
  }

  .googleButton,
  .emailButton {
    width: 100%;
  }

  .form {
    gap: 1rem;
  }

  .privacyNote {
    max-width: 100%;
  }
}

@media (max-width: 400px) {
  .authCard {
    padding: 1.5rem 1rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 1rem;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Poppins:wght@400;600;700&display=swap');

:root {
  --primary-color: #6200ea;
  --secondary-color: #b388ff;
  --accent-color: #3d5afe;
}

.container {
  font-family: 'Poppins', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  overflow: hidden;
  position: relative;
}

.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1;
}

.title {
  font-family: 'Fredoka One', cursive;
  font-size: 4rem;
  color: white;
  text-shadow: 2px 2px 0 var(--accent-color), 4px 4px 0 rgba(0,0,0,0.2);
  margin-bottom: 0.5rem;
  text-align: center;
  animation: bounce 2s infinite;
}

.subtitle {
  font-size: 1.2rem;
  color: white;
  opacity: 0.9;
  margin-bottom: 2rem;
  text-align: center;
}

.gameModesGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  width: 100%;
  max-width: 400px;
}

.gameMode {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  padding: 1.5rem;
  text-align: center;
  text-decoration: none;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
}

.gameMode:active {
  transform: scale(0.98);
}

.gameModeContent {
  position: relative;
  z-index: 2;
}

.gameMode h2 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  font-family: 'Fredoka One', cursive;
}

.gameMode p {
  font-size: 1rem;
  opacity: 0.9;
}

.emoji {
  font-size: 3rem;
  display: block;
  margin-top: 0.5rem;
}

.quickGame { background-color: rgba(52, 152, 219, 0.3); }
.twistDuel { background-color: rgba(231, 76, 60, 0.3); }
.storyCircle { background-color: rgba(46, 204, 113, 0.3); }

.decorations {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
}

.bubble {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  animation: float 8s infinite;
}

.bubble:nth-child(1) {
  width: 60px;
  height: 60px;
  left: 10%;
  top: 40%;
}

.bubble:nth-child(2) {
  width: 40px;
  height: 40px;
  right: 20%;
  top: 30%;
  animation-delay: 2s;
}

.bubble:nth-child(3) {
  width: 30px;
  height: 30px;
  left: 30%;
  bottom: 30%;
  animation-delay: 4s;
}

@keyframes float {
  0%, 100% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(-15px) rotate(180deg); }
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@media screen and (min-width: 768px) {
  .main {
    padding: 2rem;
  }

  .title {
    font-size: 6rem;
  }

  .subtitle {
    font-size: 1.5rem;
  }

  .gameModesGrid {
    grid-template-columns: repeat(3, 1fr);
    max-width: 1200px;
  }

  .gameMode:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }

  .gameMode h2 {
    font-size: 2.2rem;
  }

  .gameMode p {
    font-size: 1.1rem;
  }

  .emoji {
    font-size: 4rem;
  }

  .bubble:nth-child(1) {
    width: 80px;
    height: 80px;
  }

  .bubble:nth-child(2) {
    width: 60px;
    height: 60px;
  }

  .bubble:nth-child(3) {
    width: 40px;
    height: 40px;
  }
}
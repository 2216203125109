@import '../variables.module.css';
@import '../styles/global.module.css';

.quickGameContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  background-color: var(--background-color, #f0f2f5);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

/* Category Sections */
.cardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.categorySection {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.categorySection:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.categoryTitle {
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  position: relative;
  padding-bottom: 0.5rem;
}

.categoryTitle::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: var(--secondary-color);
  border-radius: 2px;
}

/* Card Grid for Quick Game */
.cardsGrid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

/* Quick Game specific card styles */
.card {
  width: 100%;
  height: 280px;
  transition: all 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.cardMedia {
  height: 80%;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.cardTitle {
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: var(--primary-color);
  color: white;
  font-weight: 600;
  border-radius: 0 0 10px 10px;
}

/* Quick Game specific action button styles */
.actionButtonsContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.actionButton {
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  transition: all 0.3s ease;
  cursor: pointer;
}

.shuffleButton {
  background-color: var(--accent-color);
  color: white;
}

.shuffleButton:hover {
  background-color: var(--accent-dark-color);
  box-shadow: 0 6px 12px rgba(255, 152, 0, 0.3);
}

.continueScriptButton {
  background-color: white;
  border: 2px solid var(--secondary-color);
  color: var(--secondary-color);
}

.continueScriptButton:hover {
  background-color: var(--secondary-color);
  color: white;
  box-shadow: 0 6px 12px rgba(245, 0, 87, 0.3);
}

.finishButton {
  background-color: var(--primary-color);
  color: white;
}

.finishButton:hover {
  background-color: var(--primary-dark-color);
  box-shadow: 0 6px 12px rgba(63, 81, 181, 0.3);
}

/* Responsive Design */
@media (max-width: 768px) {
  .quickGameContainer {
    padding: 1rem;
  }

  .cardsContainer {
    grid-template-columns: 1fr;
  }

  .categoryTitle {
    font-size: 1.4rem;
  }

  .card {
    height: 240px;
  }

  .cardMedia {
    height: 75%;
  }

  .cardTitle {
    height: 25%;
    font-size: 0.9rem;
  }

  .actionButtonsContainer {
    flex-direction: column;
    align-items: stretch;
  }

  .actionButton {
    width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .cardsContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .card {
    height: 260px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .categorySection,
  .card,
  .actionButton {
    transition: none;
  }
}
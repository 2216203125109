.actionButtons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-top: 3rem;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
  }
  
  .actionButton {
    position: relative;
    min-width: 200px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 1rem 1.5rem;
    border-radius: 50px;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    outline: none;
    border: none;
  }
  
  .actionButton::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(255,255,255,0.1), rgba(255,255,255,0.5));
    transform: translateY(-100%);
    transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  
  .actionButton:hover::before {
    transform: translateY(0);
  }
  
  .actionButton:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }
  
  .actionButton:active {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .actionButton:focus {
    box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.3);
  }
  
  .primaryButton {
    background-color: var(--primary-color, #3f51b5);
    color: white;
  }
  
  .primaryButton:hover {
    background-color: var(--primary-dark-color, #303f9f);
  }
  
  .secondaryButton {
    background-color: white;
    border: 2px solid var(--secondary-color, #f50057);
    color: var(--secondary-color, #f50057);
  }
  
  .secondaryButton:hover {
    background-color: var(--secondary-color, #f50057);
    color: white;
  }
  
  .actionButton span {
    position: relative;
    z-index: 1;
  }
  
  .actionButton i {
    margin-right: 0.5rem;
    transition: transform 0.3s ease;
  }
  
  .actionButton:hover i {
    transform: translateX(-3px);
  }
  
  .ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 0.6s linear;
    background-color: rgba(255, 255, 255, 0.7);
  }
  
  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
  
  .disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .disabled:hover {
    transform: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .actionButtons {
      flex-direction: column;
      gap: 1rem;
      padding: 1.5rem;
    }
  
    .actionButton {
      width: 100%;
      min-width: unset;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .actionButton,
    .actionButton::before,
    .actionButton i {
      transition: none;
    }
  
    .ripple {
      animation: none;
    }
  }
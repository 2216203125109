@import '../styles/global.module.css';

/* Player Prompt */
.playerPrompt {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.playerPrompt:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

/* Category Section */
.categorySection {
  margin-bottom: 3rem;
}

.categoryTitle {
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  position: relative;
  padding-bottom: 0.5rem;
}

.categoryTitle::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: var(--secondary-color);
  border-radius: 2px;
}

/* Cards Grid */
.cardsGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  width: 100%;
  height: 200px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.selected {
  border: 2px solid #6200ee;
}

.cardMedia {
  height: 140px;
  object-fit: cover;
}

.cardContent {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.actionButtons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.generateScript, .nextPlayer, .shuffleCards {
  min-width: 150px;
}

.scriptContainer {
  margin-top: 20px;
  padding: 20px;
}

.scriptText {
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* Responsive Design */
@media (max-width: 768px) {
  .playerPrompt {
    font-size: 1.4rem;
    padding: 1rem;
  }

  .categoryTitle {
    font-size: 1.4rem;
  }

  .cardsGrid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .cardsGrid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

@media (min-width: 1025px) {
  .cardsGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}
.aboutContainer {
    padding: 40px;
    max-width: 800px;
    margin: 0 auto;
    font-family: var(--font-family);
    color: #333333;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.aboutContainer h1 {
    font-size: 2.5em;
    color: #6200ea;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.aboutContainer h2 {
    font-size: 1.8em;
    color: #3700b3;
    margin-top: 30px;
    margin-bottom: 15px;
    border-bottom: 2px solid #3700b3;
    padding-bottom: 5px;
}

.aboutContainer p {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 15px;
}

.aboutContainer ul {
    list-style-type: none;
    padding-left: 20px;
    margin-bottom: 15px;
}

.aboutContainer li {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 10px;
    position: relative;
}

.aboutContainer li::before {
    content: '•';
    color: #6200ea;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    position: absolute;
    left: -10px;
}

.appDescription, .nameExplanation, .howItWorks, .ourMission, .getStarted {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.nameExplanation strong {
    color: #6200ea;
    font-weight: 600;
}

.howItWorks ul {
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 20px 0;
}

.howItWorks li {
    background-color: #e8eaf6;
    padding: 10px 20px;
    border-radius: 20px;
    font-weight: 500;
}

.ourMission {
    background-color: #ede7f6;
    border-left: 5px solid #6200ea;
}

.getStarted {
    text-align: center;
    background-color: #e8f5e9;
}

.getStarted h2 {
    color: #2e7d32;
    border-bottom: none;
}

.getStarted p {
    font-size: 1.2em;
    font-weight: 500;
}

.categories {
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 20px 0;
}

.categories li {
    background-color: #e8eaf6;
    padding: 10px 20px;
    border-radius: 20px;
    font-weight: 500;
    list-style-type: none;
}

@media (max-width: 600px) {
    .aboutContainer {
        padding: 20px;
    }

    .aboutContainer h1 {
        font-size: 2em;
    }

    .aboutContainer h2 {
        font-size: 1.5em;
    }

    .aboutContainer p, .aboutContainer li {
        font-size: 1em;
    }

    .howItWorks ul {
        flex-direction: column;
        align-items: center;
    }

    .howItWorks li {
        margin-bottom: 10px;
    }
}
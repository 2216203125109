.container {
    min-height: 100vh;
    background: linear-gradient(135deg, var(--background-color, #1a2a3a), var(--primary-light-color, #2c3e50));
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .contentWrapper {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    border-radius: 30px;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.3);
    padding: 3rem;
    transition: all 0.4s ease;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .mainContent {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .gameTitle {
    font-size: 3rem;
    font-weight: 700;
    color: var(--accent-color, #f1c40f);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  
  .loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
  }
  
  .loadingSpinner {
    width: 50px;
    height: 50px;
    border: 5px solid var(--primary-color);
    border-top: 5px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .button {
    padding: 0.75rem 1.5rem;
    background-color: var(--accent-color, #f1c40f);
    color: var(--primary-color, #2c3e50);
    border: none;
    border-radius: 10px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .button:hover {
    background-color: var(--accent-light-color, #f39c12);
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .inputField {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid var(--primary-light-color, #34495e);
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--text-color, #ecf0f1);
    border-radius: 10px;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .inputField:focus {
    border-color: var(--accent-color, #f1c40f);
    box-shadow: 0 0 0 3px rgba(241, 196, 15, 0.3);
    outline: none;
  }
  
  @media (max-width: 1024px) {
    .contentWrapper {
      padding: 2rem;
      flex-direction: column;
    }

    .mainContent {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .contentWrapper {
      padding: 1.5rem;
      border-radius: 20px;
    }
  
    .gameTitle {
      font-size: 2rem;
    }
  }
  
  @media (min-width: 1025px) {
    .contentWrapper {
      padding: 4rem;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .contentWrapper,
    .button,
    .inputField {
      transition: none;
    }
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }
  
  .gameElement {
    animation: pulse 2s infinite;
  }
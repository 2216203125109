/* global.module.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

:root {
  --font-family: 'Roboto', Arial, sans-serif;
  --primary-color: #6a0dad;
  --secondary-color: #ffffff;
  --text-color: #333333;
  --background-color: #f0f2f5;
  --accent-color: #ff9800;
  --error-color: #f44336;
  --success-color: #4caf50;
  --border-radius: 8px;
  --box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
}

/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global Styles */
body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  font-size: 16px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: var(--transition);
  font-weight: 500;
  text-align: center;
  text-decoration: none;
}

.button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  font-size: 16px;
  transition: var(--transition);
}

.input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(106, 13, 173, 0.1);
}

.card {
  background-color: var(--secondary-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 20px;
  margin-bottom: 20px;
}

.heading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.text {
  margin-bottom: 15px;
}

.error {
  color: var(--error-color);
}

.success {
  color: var(--success-color);
}

/* Responsive design */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .heading {
    font-size: 20px;
  }

  .button {
    width: 100%;
  }
}
@import '../variables.module.css';
@import '../styles/global.module.css';

.contactContainer {
  padding: 40px;
  max-width: 700px;
  margin: 0 auto;
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: #f4f4f4;
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 0.6s ease-in-out;
}

.contactContainer h2 {
  font-size: 2.5em;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 25px;
}

.contactContainer p {
  font-size: 1.4em;
  line-height: 1.8;
  text-align: center;
  margin-bottom: 30px;
}

.options {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.options button {
  padding: 15px 25px;
  font-size: 1.2em;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.options button:hover {
  background-color: var(--accent-color);
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);
}

.contactInfo {
  margin-top: 35px;
  text-align: center;
  animation: pulse 1.5s infinite;
}

.contactInfo p {
  margin: 15px 0;
  font-size: 1.2em;
}

.contactInfo button {
  margin-top: 20px;
  padding: 12px 30px;
  font-size: 1.1em;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contactInfo button:hover {
  background-color: var(--accent-color);
}

.resetButton {
  margin-top: 20px;
  padding: 15px 35px;
  font-size: 1.2em;
  background-color: #ff4757;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.resetButton:hover {
  background-color: #ff6b81;
  transform: translateY(-3px);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Responsive Styles */
@media (max-width: 600px) {
  .contactContainer {
    padding: 20px;
  }

  .contactContainer h2 {
    font-size: 2rem;
  }

  .contactContainer p {
    font-size: 1.2rem;
  }

  .options button {
    padding: 12px 20px;
    font-size: 1rem;
  }

  .resetButton {
    padding: 12px 25px;
  }
}

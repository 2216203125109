.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #6200ea;
    padding: 15px 20px;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.navbar:hover {
    background-color: #7c4dff;
}

.brandLink {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.8em;
    font-weight: bold;
    transition: color 0.3s ease;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.brandLink:hover {
    color: #03dac6;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    width: 25px;
}

.bar {
    height: 3px;
    width: 100%;
    background-color: #ffffff;
    margin: 4px 0;
    transition: all 0.3s ease;
}

.active .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.active .bar:nth-child(2) {
    opacity: 0;
}

.active .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

.navLinks {
    display: flex;
    align-items: center;
    list-style: none;
}

.navItem {
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.navLink {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.1em;
    transition: color 0.3s ease;
    position: relative;
}

.navLink::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #03dac6;
    transition: width 0.3s ease;
}

.navLink:hover::after {
    width: 100%;
}

.navLink:hover {
    color: #03dac6;
}

.userButton {
    color: #ffffff;
    padding: 0;
    background-color: transparent;
    border: 2px solid #ffffff;
    border-radius: 20px;
    padding: 5px 15px;
    transition: all 0.3s ease;
}

.userButton:hover {
    background-color: #ffffff;
    color: #6200ea;
}

@media (max-width: 768px) {
    .hamburger {
        display: flex;
    }

    .navLinks {
        position: absolute;
        top: 60px;
        left: -100%;
        flex-direction: column;
        width: 100%;
        background-color: #6200ea;
        transition: left 0.3s ease;
        padding: 20px 0;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .navLinks.mobileActive {
        left: 0;
    }

    .navItem {
        margin: 15px 0;
        justify-content: center;
        opacity: 0;
        transform: translateY(-20px);
        transition: opacity 0.3s ease, transform 0.3s ease;
    }

    .navLinks.mobileActive .navItem {
        opacity: 1;
        transform: translateY(0);
    }

    .navLink {
        font-size: 1.5em;
    }
}

.logo {
    height: 40px; /* Adjust as needed */
    width: auto;
}

